import Splide from '@splidejs/splide';


$(window).on('load', function() {
    new Splide( '#splide', {
        type   : 'loop',
        // height   : '9rem',
        // autoWidth: true,
        // focus    : 'center',
        padding: {
            right: '5rem',
            left : '5rem',
        },
        breakpoints: {
            575: {
                padding: {
                    right: '1.5rem',
                    left : '1.5rem',
                },
            },
        }
        // "padding":{"left":"5rem","right":"5rem"},"breakpoints":{"600":{"height":"6rem"}}
    } ).mount();

    $select2.on("change", function (e) {

        if ($(this).attr('id') == 'marca') {

            console.log($(this).val());

            var marca = $(this).val(),
                action = URL_BASE+"produtos-by-marca/"+marca;

            window.showLoading();
            $.getJSON(action, function (data) {
                window.hideLoading();

                if (data.resultado === "0") {
                    var myModalEl = document.getElementById('alertModal');
                    var myModal = new bootstrap.Modal(myModalEl, {
                        // keyboard: false
                    });
                    myModalEl.addEventListener('show.bs.modal', function (event) {
                        // var modalTitle = modalDialog.querySelector('.modal-title');
                        var modalBody = myModalEl.querySelector('.modal-body');
                        // var modalBtnSecondary = modalDialog.querySelector('.btn-secondary');
                        // var modalBtnPrimary = modalDialog.querySelector('.btn-primary');

                        modalBody.innerHTML = data.resultado_txt;
                    });
                    myModal.show();
                    window.initMapMarkers([]);

                    $('#produto').val(null).trigger('change');
                }else{
                    var $produtos = data.produtos,
                        $select = $('#produto'),
                        produto = $select.val();

                    $select.find('option').remove();

                    var option = new Option('', '', false, true);
                    $select.append(option);
                    for (var d in $produtos) {
                        if (typeof produto !== 'undefined'
                            && $.trim(produto) !== ''
                            && $.trim(produto) === $produtos[d].codprod
                            ) {
                            var option = new Option($produtos[d].nome, $produtos[d].codprod, true, true);
                        }else{
                            var option = new Option($produtos[d].nome, $produtos[d].codprod, false, false);
                        }
                        $select.append(option);
                    }
                    // console.log(options);

                    // manually trigger the `select2:select` event
                    $select.trigger({
                        type: 'select2:select',
                        params: {
                            data: $produtos
                        }
                    });
                }

            });

        }
    });

    window.cepold = '';
    $(".button-search-clients").on('click', function (e) {

        var marca = $('#marca').val(),
            produto = $('#produto').val(),
            cep = $('#cep').val(),
            params = {},
            $form = $(e.target).closest('form'),
            action = $form.attr('action');

        if ($.trim(marca) !== ''
            || $.trim(produto) !== '') {
            params = {marca:marca, produto:produto}
        }

        window.showLoading();
        $.getJSON(action, params, function (data) {
            if (data.resultado === "0") {
                var myModalEl = document.getElementById('alertModal');
                var myModal = new bootstrap.Modal(myModalEl, {
                    // keyboard: false
                });
                myModalEl.addEventListener('show.bs.modal', function (event) {
                    // var modalTitle = modalDialog.querySelector('.modal-title');
                    var modalBody = myModalEl.querySelector('.modal-body');
                    // var modalBtnSecondary = modalDialog.querySelector('.btn-secondary');
                    // var modalBtnPrimary = modalDialog.querySelector('.btn-primary');

                    modalBody.innerHTML = data.resultado_txt;
                });
                myModal.show();
                window.initMapMarkers([]);

                $('#cidade').val(null).trigger('change');
            }else{
                var $itens = data.itens;

                locations = [],
                myLatLng = {lat: -28.450562795904478, lng: -50.882438461913};
                $itens.forEach(function($cli, i) {
                    var address = $cli.address + "<br>" + $cli.bairro + " - " + $cli.cidade + "/" +  $cli.uf + "<br><span class='fw-bolder'>CEP:</span>"  + $cli.cep;
                    var addressLink = $cli.address + " - " + $cli.bairro + ", " + $cli.cidade + " - " +  $cli.uf + ", "  + $cli.cep;
                    locations.push(["<h3 class='fs-6'>" + $cli.razao_social + "</h3>"+address+"<br><br><a href='https://www.google.com.br/maps/dir//"+addressLink+"' target='_blank' class='link-info text-decoration-none'><i class='fas fa-route'></i> Como chegar?</a>", $cli.latitude, $cli.longitude, i]);

                    if (i == 0
                        && (typeof params.marca !== 'undefined' || typeof params.produto !== 'undefined')) {
                        myLatLng = {lat: parseFloat($cli.latitude), lng: parseFloat($cli.longitude)};
                        document.getElementById('zoom').value = 6;
                    }
                });

                if (cep.length == 9
                    && cep !== window.cepold) {
                    window.cepold = cep;
                    searchAddress(cep);
                }

                if (typeof params.marca === 'undefined'
                    && typeof params.produto === 'undefined') {
                    document.getElementById('zoom').value = 6;
                }

                if ($("#map").length > 0
                    && typeof locations !== 'undefined'
                    && typeof myLatLng !== 'undefined'
                    && locations.length > 0) {
                    window.initMapMarkers(locations);
                }

                // console.log(data);;
            }
            window.hideLoading();
        });
	});

    // window.cepold = '';
    // $('#cep').on("keyup", function (e) {
    //     var $cep = $(this);

    //     if ($cep.val().length == 9
    //         && $cep.val() !== window.cepold) {
    //         window.cepold = $cep.val();
    //         // console.log($cep.val());

    //         showLoading();
    //         searchAddress($cep.val());
    //         hideLoading();
    //     }
    // });

});
